<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <validation-observer ref="addReceivingValidation">
      <b-card class="product-add-wrapper p-1">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Chọn Kho"
                rules="required"
              >
                <h5 class for>
                  Chọn Kho
                  <span class="text-danger">*</span>
                </h5>
                <SelectInventory
                  v-model="inventoryInfo"
                  @onChangeInventory="handleChangeInventory"
                />

                <small class="text-danger">{{
                  errors[0] ? "Vui lòng chọn Kho" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('lbl_distributor')"
                rules="required"
              >
                <h5 class for>
                  {{ $t("lbl_distributor") }}
                  <span class="text-danger">*</span>
                </h5>

                <v-select
                  :placeholder="$t('lbl_select_distributor')"
                  id="region-edit-vendor"
                  push-tags
                  taggable
                  :createOption="handleLog"
                  label="name"
                  value="id"
                  :reduce="(option) => option.id"
                  v-model="providerID"
                  :options="productProviders"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon icon="ChevronDownIcon" size="20" />
                    </span>
                  </template>
                  <template v-slot:option="option"
                    >{{ option.name }} {{ !option.id ? "(Tạo)" : "" }}</template
                  >
                </v-select>
                <small class="text-danger">{{
                  errors[0] ? $t("lbl_select_distributor") : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group>
              <validation-provider :name="$t('lbl_invoice_number')">
                <h5 class for>{{ $t("lbl_invoice_number") }}</h5>
                <b-form-input v-model="numberInvoice" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group>
              <validation-provider :name="$t('lbl_invoice_date')">
                <h5 class for>{{$t('lbl_invoice_date')}}</h5>
                <b-form-datepicker v-model="invoiceDate" />
              </validation-provider>
            </b-form-group>
          </b-col>-->
          <b-col md="12">
            <SelectEcomProduct
              @onUpdateList="handleUpdateListReceiving"
              :listReceiving="listReceiving"
            />
          </b-col>
          <b-col md="12 mt-2">
            <h4>{{ $t("lbl_list_products") }}</h4>
            <b-table
              :tbody-tr-class="checkNew"
              responsive
              :items="listReceiving"
              :fields="tableColumns"
              class="cs-table"
            >
              <template #cell(product_code)="data">
                <span>#{{ data.item.product && data.item.product.sku }}</span>
              </template>
              <template #cell(name)="data">
                <span>{{ data.item.product && data.item.product.name }}</span>
                <div v-if="data.item.lot.length > 0" class="mt-1">
                  <div v-for="lot in data.item.lot" :key="lot.id">
                    <b-badge pill variant="light-primary">{{
                      getDetailLot(lot)
                    }}</b-badge>
                  </div>
                </div>
              </template>
              <template #cell(amount)="data">
                <b-form-input
                  :disabled="data.item.lot.length == 0"
                  type="number"
                  v-model="data.item.amount"
                />
              </template>
              <template #cell(lot_number)="data">
                <b-badge
                  variant="primary"
                  @click="openModalAppendLot(data.item)"
                  >Chọn lô</b-badge
                >
              </template>
              <template #cell(unit_id)="data">
                <span>{{ getUnit(data.item) }}</span>
              </template>
              <template #cell(action)="data">
                <span
                  class="cursor-pointer"
                  @click="handleDeleteProduct(data.item)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="24"
                    class="text-danger"
                  />
                </span>
              </template>
            </b-table>
          </b-col>
          <b-col
            cols="12"
            class="mt-3 d-flex align-items-center justify-content-end"
          >
            <b-button
              @click="saveReceiving"
              :disabled="loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              >{{ $t("lbl_create_bill") }}</b-button
            >
            <b-button
              :disabled="loading"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="
                () => {
                  $router.go(-1);
                }
              "
              variant="outline-secondary"
              >{{ $t("lbl_exits") }}</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
    <choose-lot-modal
      :is-show-modal-choose-lot.sync="showChooseLot"
      :product-variant="currentProductVariant"
      @setLot="setLot"
    />
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import vSelect from "vue-select";
import i18n from "../../../libs/i18n";
import SelectEcomProduct from "./SelectEcomProduct.vue";
import ChooseLotModal from "./Modal/ChooseLotModal.vue";
import SelectInventory from "@/components/SelectInventory/index.vue";
import { RECEIVING_TYPE } from "@/utils/constant";
import store from "@/store";

const tableColumns = [
  {
    key: "product_code",
    label: "SKU",
    sortable: false,
    // thStyle: { width: "8%" }
  },
  {
    key: "name",
    label: i18n.t("lbl_product_name_short"),
    sortable: false,
    // thStyle: { width: "16%" }
  },
  {
    key: "lot_number",
    // label: i18n.t("lbl_lot_number"),
    label: "#",
    sortable: false,
    // thStyle: { width: "16%" }
  },
  {
    key: "amount",
    label: i18n.t("lbl_amount"),
    sortable: false,
    // thStyle: { width: "8%" }
  },
  {
    key: "unit_id",
    label: i18n.t("lbl_unit"),
    sortable: false,
    // thStyle: { width: "8%" }
  },
  {
    key: "action",
    label: "",
    sortable: false,
    // thStyle: { width: "2%" }
  },
];

export default {
  name: "AddReceiving",
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    SelectEcomProduct,
    ChooseLotModal,
    SelectInventory,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      listReceiving: [],
      tableColumns,
      productProviders: [],
      inventories: [],
      providerID: "",
      required,
      numberInvoice: "",
      invoiceDate: window.moment().format("YYYY-MM-DD"),
      appUtils,
      products: [],
      showChooseLot: false,
      currentProductVariant: null,
      inventoryInfo: null,
      valueInput: "",
    };
  },
  setup() {
    const toast = useToast();

    return {
      toast,
      useToast,
    };
  },
  created() {
    this.fetchProvider();
  },
  methods: {
    handleLog(data) {
      this.valueInput = data;
      return {
        name: data,
        id: null,
      };
    },
    getDetailLot(data) {
      let result = "";
      const name = data.name;
      result += name;
      if (data.manufacture_date) {
        const dateTimeManu = appUtils.formatUnixTimeToDate(
          data.manufacture_date
        );
        result += " | Ngày sản xuất: " + dateTimeManu;
      }

      if (data.expiration_date) {
        const dateTimeManu = appUtils.formatUnixTimeToDate(
          data.expiration_date
        );
        result += " | Ngày hết hạn: " + dateTimeManu;
      }
      return result;
    },
    fetchProvider() {
      const userData = appUtils.getLocalUser();
      const params = {
        supplier_id: userData.supplierInfo.id,
        page_num: 1,
        page_size: 100,
        sort_by: "id",
        order: "desc",
      };
      this.$store.dispatch("ecomProvider/fetchProvider", params).then((res) => {
        this.productProviders = res.data.data;
      });
    },
    async saveReceiving() {
      this.$refs.addReceivingValidation.validate().then(async (success) => {
        if (success) {
          try {
            const isValid = this.handleCheckProduct();
            if (!isValid) return;

            const userLocal = JSON.parse(localStorage.getItem("user"));
            if (!userLocal?.id || !userLocal?.supplierInfo?.id) return;
            this.loading = true;
            const payload = {
              invoice_code: this.numberInvoice,
              provider_id: this.providerID,
              inventory_id: this.inventoryInfo.id,
              supplier_id: userLocal?.supplierInfo?.id,
              stock_in_type: RECEIVING_TYPE.receiving,
              stock_in_details: this.listReceiving.map((x) => {
                return {
                  product_lot_id: x.lot[0].id,
                  product_variant_id: x.ID,
                  variant_qty: parseInt(x.amount),
                };
              }),
            };

            await this.$store
              .dispatch("stockIn/createStockin", payload)
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Tạo phiếu nhập thành công",
                    icon: "CheckCircleIcon",
                    variant: "success",
                  },
                });
                this.$router.replace({
                  name: "ReceivingDetail",
                  params: {
                    id: res.data?.data?.id,
                  },
                });
              });
          } catch (error) {
            console.log(error);
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tạo phiếu nhập thất bại",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async createProvider(data) {
      try {
        return await store
          .dispatch("configProduct/createProvider", {
            name: data,
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
          })
          .then((response) => {
            const newID = response.data.id;
            this.providerID = newID;
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Thêm nhà phân phối thành công",
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
            return true;
          });
      } catch (error) {
        console.log(error);
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi thêm nhà phân phối",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return false;
      } finally {
        // isLoading.value = false
      }
    },
    handleUpdateListReceiving(value) {
      this.listReceiving.push({
        ...value,
        isNew: true,
        lot: [],
      });
    },
    checkNew(item, type) {
      if (!item || type !== "row") return;
      if (item.isNew) {
        setTimeout(() => {
          item.isNew = false;
        }, 1000);
        return "table-success";
      }
    },
    getUnit(data) {
      return data?.product_unit?.name || "";
    },
    openModalAppendLot(data) {
      this.showChooseLot = true;
      this.currentProductVariant = data;
    },
    setLot(data) {
      const indexreceiving = this.listReceiving.findIndex(
        (x) => x.ID === this.currentProductVariant.ID
      );
      if (indexreceiving !== -1) {
        this.listReceiving[indexreceiving].lot = data;
      }
    },
    handleChangeInventory(data) {
      this.inventoryInfo = data;
    },
    handleDeleteProduct(medicine) {
      this.listReceiving = this.listReceiving.filter(
        (item) => item?.ID !== medicine?.ID
      );
    },
    handleCheckProduct() {
      if (!this.listReceiving?.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vui lòng chọn danh mục sản phẩm",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });

        return;
      }

      const isAllAmount = this.listReceiving?.every((item) =>
        Number(item?.amount)
      );

      const isChooseLot = this.listReceiving?.every(
        (item) => item.lot.length > 0
      );

      if (!isAllAmount) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vui lòng nhập đủ số lượng",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return false;
      }
      if (!isChooseLot) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vui lòng nhập số lô tương ứng",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });

        return false;
      }
      return true;
    },
  },
  watch: {
    async providerID(value) {
      if (value !== null || this.valueInput === "") return;
      else {
        await this.createProvider(this.valueInput);
        await this.fetchProvider();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import "~swiper/css/swiper.css";
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
  display: none;
}

.is-new {
  background-color: rgba(29, 68, 177, 0.12);
}

.cs-table table {
  // min-width: 1700px;
}
</style>
