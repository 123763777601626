<template>
  <div>
    <b-modal
      size="lg"
      :visible="isShowModalChooseLot"
      title="Chọn lô"
      cancel-title="Close"
      centered
      ok-title="Accept"
      cancel-variant="outline-secondary"
      hide-footer
      @hide="$emit('update:is-show-modal-choose-lot', false)"
    >
      <b-col md="12">
        <div class="flex justify-end mb-1">
          <b-button
            @click="handleShowAddLot"
            size="sm"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            >Tạo Lô</b-button
          >
        </div>
        <b-table
          responsive
          :items="ecomLots"
          :fields="tableColumns"
          select-mode="single"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(name)="data">
            <span>#{{ data.item.name }}</span>
          </template>
          <template #cell(manufactureDate)="data">
            <span>{{
              appUtils.formatUnixTimeToDate(data.item.manufacture_date)
            }}</span>
          </template>
          <template #cell(expireDate)="data">
            <span>{{
              appUtils.formatUnixTimeToDate(data.item.expiration_date)
            }}</span>
          </template>
        </b-table>
      </b-col>
    </b-modal>
    <ModalAddLot
      ref="ModalAddLot"
      :productVariant="productVariant"
      @onRefresh="fetchEcomLots"
    />
  </div>
</template>
<script>
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { ref, watch } from "@vue/composition-api";
import moment from "moment";
import i18n from "../../../../libs/i18n";
import appUtils from "../../../../utils/appUtils";
import ModalAddLot from "./ModalAddLot.vue";

const tableColumns = [
  {
    key: "name",
    label: "Tên lô",
    sortable: false,
    // thStyle: { width: "16%" }
  },
  {
    key: "manufactureDate",
    label: "Ngày sản xuất",
    sortable: false,
    // thStyle: { width: "8%" }
  },
  {
    key: "expireDate",
    label: "Ngày hết hạn",
    sortable: false,
    // thStyle: { width: "2%" }
  },
];

export default {
  components: {
    BModal,
    ModalAddLot,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      tableColumns,
      appUtils,
    };
  },
  watch: {
    isShowModalChooseLot(value) {
      if (!value) {
        return;
      }
      this.fetchEcomLots();
    },
  },
  props: {
    isShowModalChooseLot: {
      type: Boolean,
      required: true,
    },
    productVariant: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const ecomLots = ref([]);
    const toast = useToast();
    const perPage = ref(10);
    const totalEcomLots = ref(0);
    const currentPage = ref(1);
    const fetchEcomLots = () => {
      if (props.productVariant !== null) {
        store
          .dispatch("ecomLot/fetchEcomLot", {
            page_size: perPage.value,
            page_num: currentPage.value,
            product_id: props.productVariant.partner_product_id,
          })
          .then((response) => {
            ecomLots.value = response.data.data;
            totalEcomLots.value = response.data.page.total;
          })
          .catch((e) => {
            console.log(e);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching trackings",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    };
    if (props.productVariantId !== 0) {
      fetchEcomLots();
    }
    watch(
      [perPage, currentPage],
      () => {
        fetchEcomLots();
      },
      {
        deep: true,
      }
    );
    return {
      ecomLots,
      perPage,
      totalEcomLots,
      currentPage,
      fetchEcomLots,
    };
  },
  methods: {
    onRowSelected(items) {
      console.log({ items });
      this.$emit("setLot", items);
      this.$emit("update:is-show-modal-choose-lot", false);
    },
    formatDateDDMMYYYY(time) {
      return moment(time).format("DD/MM/YYYY");
    },
    handleShowAddLot() {
      this.$refs.ModalAddLot.showModal();
    },
  },
};
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
</style>
