<template>
  <div>
    <h6 class>Chọn danh mục sản phẩm</h6>

    <div class="cs-relative">
      <div class="cs-relative" v-click-outside="handleClose">
        <b-form-input
          v-model="searchQuery"
          @update="handleSearch"
          @click="handleClick"
          autocomplete="off"
          placeholder="Nhập mã, tên danh mục thuốc"
          ref="inputRef"
        />
        <div class="cs_down_icon">
          <div class="d-flex cursor-pointer" style="gap: 10px">
            <feather-icon
              v-if="searchQuery"
              @click="handleClearSelected"
              icon="XCircleIcon"
              size="20"
            />
            <feather-icon icon="ChevronDownIcon" size="20" />
          </div>
        </div>
      </div>
      <div v-show="isShowOptions" class="cs-table">
        <template v-if="productData && productData.length">
          <div class="table-header-container">
            <div class="row cs-header">
              <div class="col-4 cs-border-right cs-header-item">SKU</div>
              <div class="col-4 cs-border-right cs-header-item">Tên thuốc</div>
              <div class="col-4 cs-border-right cs-header-item">Đơn vị</div>
            </div>
          </div>
          <div class="table-container">
            <div class="cs-body" id="tableID">
              <b-overlay
                id="overlay-background"
                :show="isLoading"
                variant="light"
                :opacity="0.75"
                blur="2px"
                rounded="sm"
              >
                <div
                  v-for="item in productData"
                  :key="item.id"
                  @click="handleSelectedItem(item)"
                  class="row cs-option"
                >
                  <div class="col-4 cs-border-right cs-item">
                    {{ `#${item.product.sku}` }}
                  </div>
                  <div class="col-4 cs-border-right cs-item">
                    {{ item.product.name }}
                  </div>
                  <div class="col-4 cs-border-right cs-item">
                    {{ getUnit(item) }}
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </template>
        <div
          v-else
          class="d-flex justify-center"
          style="border-bottom: 1px solid #9b9b9b"
        >
          No product found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useProductsList from "./receivingManager";
import appUtils from "../../../utils/appUtils";

export default {
  name: "SelectEcomProduct",
  props: {
    listReceiving: Array,
  },
  data() {
    return {
      isShowOptions: false,
    };
  },
  setup() {
    const {
      fetchEcomProducts,
      productData,
      currentPage,
      totalPage,
      searchQuery,
      isLoading,
    } = useProductsList();

    return {
      fetchEcomProducts,
      productData,
      currentPage,
      totalPage,
      searchQuery,
      isLoading,
    };
  },
  computed: {},
  watch: {
    isShowOptions: {
      handler: function (isShow) {
        const table = document.querySelector("#tableID");
        if (table) {
          table.addEventListener("scroll", (e) => {
            if (
              table.scrollTop + table.clientHeight >=
              table.scrollHeight - 10
            ) {
              const nextPage = this.currentPage + 1;

              if (nextPage <= this.totalPage) {
                this.currentPage = nextPage;
                this.fetchEcomProducts(true);
              }
            }
          });
        }
      },
      deep: true,
    },
  },

  created() {
    this.fetchEcomProducts();
  },
  mounted() {},
  methods: {
    handleClick() {
      this.isShowOptions = !this.isShowOptions;
    },
    handleClose() {
      this.isShowOptions = false;
    },
    handleSearch(value) {
      this.searchQuery = value;
      this.currentPage = 1;
      this.handleDebounce();
    },
    handleDebounce: appUtils.debounce(function () {
      this.fetchEcomProducts();
    }, 1000),
    handleSelectedItem(item) {
      const hasData = this.listReceiving?.some(
        (itemReceiving) => itemReceiving?.ID === item?.ID
      );
      if (hasData) return;

      this.$emit("onUpdateList", item);
    },
    handleClearSelected(e) {
      e.stopPropagation();
      this.searchQuery = "";
      this.$refs.inputRef.focus();
      this.fetchEcomProducts();
    },

    getActiveIngredient(data) {
      return data?.active_ingredient_info?.name || "";
    },
    getUnit(data) {
      return data?.product_unit?.name || "";
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
  },
};
</script>

<style lang="scss" scoped>
.cs_down_icon {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.cs-relative {
  position: relative;
}

.table-container {
  padding-right: 5px;
  border-bottom: 1px solid #9b9b9b;
}

.table-header-container {
  padding-right: 5px;
  border-bottom: 1px solid #9b9b9b;
}

.cs-table {
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
  border: 1px solid #9b9b9b;
  border-bottom: unset;
  margin: 0;
  max-height: 333px;
  border-radius: 0;
}

.cs-header {
  // border-bottom: 1px solid #9b9b9b;
  margin: 0;
}

.cs-border-right {
  border-right: 1px solid #9b9b9b;
}
.cs-border-bottom {
  border-bottom: 1px solid #9b9b9b;
}
.cs-header-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.cs-item {
  padding: 8px;
}

.cs-option:hover {
  cursor: pointer;
  background-color: rgba(29, 68, 177, 0.12);
}
.cs-option {
  margin: 0;
}
.cs-body {
  overflow-y: scroll;
  max-height: 300px;
  margin: 0;
  margin-right: -5px;
  position: relative;

  &::-webkit-scrollbar {
    background-color: red;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;

    background: #888;
  }
}
</style>