<template  >
  <b-modal
    id="modal-prevent-closing"
    ref="modal-add-lot"
    :title="$t('Tạo Lô')"
    :ok-title="$t('Tạo mới')"
    :cancel-title="$t('Huỷ')"
    cancel-variant="outline-secondary"
    @hidden="handleClose"
    @ok="handleConfirm"
    :cancel-disabled="isLoading"
    :ok-disabled="isLoading"
    size="lg"
    centered
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Tên Lô"
                rules="required"
              >
                <h6 class="required">Tên Lô</h6>
                <b-form-input
                  v-model="form.lotName"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Nhập tên Lô')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('Ngày sản xuất')"
                rules="required"
              >
                <h6 class="required">Ngày sản xuất</h6>

                <b-form-datepicker
                  id="manufactureDate"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Chọn ngày sản xuất')"
                  v-model="form.manufactureDate"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('Ngày hết hạn')"
                rules="required"
              >
                <h6 class="required">Ngày hết hạn</h6>

                <b-form-datepicker
                  id="expiredDate"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Chọn ngày hết hạn')"
                  v-model="form.expiredDate"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";

export default {
  name: "ModalAddLot",
  components: { ValidationProvider, ValidationObserver },
  props: {
    productVariant: Object,
  },
  data() {
    return {
      form: {
        lotName: "",
        manufactureDate: null,
        expiredDate: null,
      },
      isLoading: false,
      required,
    };
  },
  methods: {
    handleClose() {
      this.$refs["modal-add-lot"].hide();
    },
    handleConfirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },

    showModal() {
      this.$refs["modal-add-lot"].show();
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const isValidDate =
            moment(this.form.manufactureDate).startOf("day").unix() <
            moment(this.form.expiredDate).endOf("day").unix();
          if (!isValidDate) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Ngày hết hạn phải lớn hơn ngày sản xuất"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });

            return;
          }

          this.handleCreateEcomLot();
        }
      });
    },
    async handleCreateEcomLot() {
      const userData = appUtils.getLocalUser();
      if (
        !this.productVariant?.partner_product_id ||
        !userData?.supplierInfo?.id
      )
        return;

      try {
        const userData = appUtils.getLocalUser();

        const params = {
          name: this.form.lotName,
          manufacture_date: moment(this.form.manufactureDate)
            .startOf("day")
            .unix(),
          expiration_date: moment(this.form.expiredDate).endOf("day").unix(),
          product_id: this.productVariant.partner_product_id,
          supplier_id: userData.supplierInfo.id,
        };

        this.isLoading = true;
        await this.$rf.getRequest("CommonRequest").createEcomLot(params);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Tạo lô thành công!"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.form = {
          lotName: "",
          manufactureDate: null,
          expiredDate: null,
        };

        this.$nextTick(() => {
          this.$refs["modal-add-lot"].hide();
        });
        this.$emit("onRefresh");
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Tạo lô thất bại!"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>